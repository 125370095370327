//var crypto = require('crypto');
const nodeRSA = require('node-rsa');

function rsaEncryption(data, publicKey){
    let key = new nodeRSA(publicKey);
    key.setOptions({encryptionScheme :'pkcs1'});
    return key.encrypt(data,'base64');
    // var key = crypto.createPublicKey(publicKey)
    // const encryptedData = crypto.publicEncrypt(
    //     {
    //       key: key,
    //       padding: crypto.constants.RSA_PKCS1_PADDING,
    //     },
    //     // We convert the data string to a buffer using `Buffer.from`
    //     Buffer.from(data)
    // );
    //
    // return encryptedData.toString('base64');
}
module.exports = rsaEncryption;
