import {
  EditFilled, MinusOutlined, PlusOutlined, RollbackOutlined, VerticalAlignBottomOutlined,DeleteOutlined
} from "@ant-design/icons";
import { Button } from "antd";
import styles from "./button.module.scss";

interface PropsType {
  disabled?: boolean;
  type?: "submit" | "cancel" | "normal";
  icon?: "new" | "edit" | "export" | "remove" | "back" | undefined | null;
  onClick?: () => void;
  loading?: boolean;
  color?: string;
  children?: React.ReactNode;
  radius?: string;
  width?: string;
  height?: string;
  border?: string;
  block?: boolean;
  btn_text?: string;
  htmlType?: "submit" | "reset" | "button" | undefined;
  style?: {};
  btn_size?: "small" | "medium" | "large";
  hidden?:boolean;
}

export const CustomButton: React.FC<PropsType> = ({
  disabled,
  icon,
  onClick,
  children,
  type,
  btn_text,
  loading,
  block,
  htmlType,
  style,
  btn_size,
  hidden,
}) => {
  const getIcon = (icon) => {
    switch (icon) {
      case "new":
        return <PlusOutlined className={styles["btn-icon"]} />;
      case "edit":
        return <EditFilled className={styles["btn-icon"]} />;
      case "export":
        return <VerticalAlignBottomOutlined className={styles["btn-icon"]} />;
      case "remove":
        return <MinusOutlined className={styles["btn-icon"]} />;
      case "back":
        return <RollbackOutlined className={styles["btn-icon"]} />;
        case "cancel":
        return <DeleteOutlined className={styles["btn-icon"]} />;
      default:
        return null;
    }
  };
  return (
    <Button
      loading={loading}
      block={block}
      shape="round"
      className={`
      ${styles[`ev_btn__${type}`]} ${styles[`ev_btn__${btn_size}`]}`}
      disabled={disabled ? true : false}
      onClick={onClick}
      htmlType={htmlType}
      style={style}
      hidden={hidden}
    >
      {getIcon(icon)}
      <b className={styles[`btn-text`]}>{btn_text}</b>
      {children}
    </Button>
  );
};
