import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionLog } from "./middlewares/actionLog";
import { userSlice } from "./user/slice";


const persistConfig = {
    key: "root",
    storage,
    blacklist: ["user"]
}

const userPersistConfig = {
    key: 'user',
    storage: storage,
    blacklist: ['loading', 'error']
}

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userSlice.reducer),
})

// add middle ware to project 
const persistedReducer = persistReducer(persistConfig, rootReducer)

//  store config
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(actionLog),
    devTools: true
})

// 
const persistor = persistStore(store)

// const store = createStore(rootReducer, applyMiddleware(thunk, actionLog));

export type RootState = ReturnType<typeof store.getState>

const exportObject = { store, persistor }

export default exportObject;