import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  DatePicker,
  Checkbox
} from "antd";
import Title from "antd/lib/typography/Title";

import {
  DialogCom,
  CustomButton
} from "../../../components";
import resMessage from "../../../constant/returnMessage";
import { customMessage } from "../../../utils/common";
import styles from "./userGroupForm.module.scss";
const apiconfig = window.apiconfig;
const moment = require('moment')
const axios = require('axios')
const title = "Update Payment";


export const ICSSPaymentUpdate = forwardRef(({ initialData, title, handleSubmit, submitLoading }: PropsType, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const [fetching, setFetching] = useState(true);

  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;



  useImperativeHandle(ref, () => ({
    openDialog() {
      setVisible(!visible);
    },
    resetForm() {
      form.resetFields();
    }
  }));

  const CusTitle = () => {
    return <Title level={4}>{title}</Title>;
  };



  const onReset = () => {
    form.resetFields();
  };

      return (
        <div>
          <DialogCom
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            title={<CusTitle />}
          >
            <Form
              className={styles["form"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={handleSubmit}

              initialValues={{
                enabled:true,
                //vc_name: "GooglePay",
                effective_from:moment()
              }}
            >
              <Row gutter={1}>
                <Col span={9}>
                  <Form.Item
                    className={styles["form__item"]}
                    name="enabled"
                    label="Enable"
                     valuePropName="checked"
                  >
                  <Checkbox >Enabled</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>

                </Col>
              </Row>

              <Col span={9}>
                <Form.Item
                  className={styles["form__item"]}
                  name="effective_from"
                  label="Effective From"                
                >
                <DatePicker showTime />

                </Form.Item>
              </Col>


              <Divider className={styles["form-divider"]} />
              <Row>
                <Form.Item>
                  <CustomButton
                    btn_size="medium"
                    type={"submit"}
                    btn_text="Update"
                    htmlType="submit"
                    loading={submitLoading}
                  ></CustomButton>
                </Form.Item>
              </Row>
            </Form>
          </DialogCom>
        </div>
      );
  })
