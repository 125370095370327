var crypto = require('crypto');
var algorithm = 'sha256';

function toQueryString(values){
    var key_array = [];
    for (const [key, value] of Object.entries(values)) {
        key_array.push(`${key}=${value}`);
    }
    var query = `?${key_array.join('&')}`

    return query;
}


function generateSignature(values, timestamp, client_id, client_secret, interface_abbr, action){
    
    var cli_secret_key = "TSP" + client_secret;

    var firsthmac = crypto.createHmac(algorithm, Buffer.from(cli_secret_key,'utf8'));
    var firstkeyBytes = firsthmac.update(timestamp, 'utf8').digest();

    var secondhmac = crypto.createHmac(algorithm, firstkeyBytes);
    var secondkeyBytes = secondhmac.update(client_id, 'utf8').digest();

    var thirdhmac = crypto.createHmac(algorithm, secondkeyBytes);
    var thirdkeyBytes = thirdhmac.update(interface_abbr, 'utf8').digest();

    var signhmac = crypto.createHmac(algorithm, thirdkeyBytes);
    var signkeyBytes = signhmac.update(action.toLowerCase(), 'utf8').digest();

    var signaturehmac = crypto.createHmac(algorithm, signkeyBytes);
    var signatureHashBytes = signaturehmac.update(toQueryString(values), 'utf8');

    return signatureHashBytes.digest('hex').toUpperCase();
}

export default  generateSignature;
