import React from "react";
import styles from "./UserLayout.module.scss";
import { Layout } from "antd";
const { Content } = Layout;

export const UserLayout: React.FC = (props) => {
  return (
    <Layout className={styles["user-layout-container"]}>
      <Content className={styles["content"]}>{props.children}</Content>
    </Layout>
  );
};
