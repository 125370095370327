import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Typography,Menu,Row,Col } from "antd";
import { MainLayout } from "../../layouts";
import styles from "./transactionPage.module.scss";
import { PrinterList } from "./list/PrinterList";
import { PrinterOutlined, FileTextOutlined }from "@ant-design/icons";
import { useHistory } from "react-router";
import { useSelector } from "../../redux/hook";
import { customMessage,getEncBody } from "../../utils/common";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/user/slice";
const { Title } = Typography;
const generateSignature =  require("../../utils/generateSignature");
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});


// const { aesEncryption, generateAESKey } = require("../../utils/aes");
// const rsaEncryption = require("../../utils/rsa");


const axios = require('axios');
const apiconfig = window.apiconfig;
var apiHost = apiconfig.apihost;
var rasHost = apiconfig.rasHost;
var client_sercet = apiconfig.client_sercet

export const ICSSPrinterLanding: React.FC = () => {
  let { path } = useRouteMatch();
  const title = "Printers Status";
  const [current, setCurrent] = useState("printing");
  const history = useHistory();
  const accesstoken = useSelector((s) => s.user.token);
  const refreshToken = useSelector((s) => s.user.refreshToken);
  var [loginCheck,setLoginCheck ] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(accesstoken === null){
      history.push("/signin");
      customMessage.error("Not Accessible")
      return;
    }
     let jwtobj =JSON.parse(Buffer.from(accesstoken.split('.')[1], 'base64').toString());
     //console.log(jwtobj);
     console.log(refreshToken);

     getEncBody({
      refresh_token:refreshToken,
      client_role:jwtobj.client_role.join(",")
    }).then((body)=>{
          var timestamp = new Date(Date.now()).toISOString();
          var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSIsFuncAccessible");

        axios({
          method: 'post',
          url: apiHost+'/ICSSCMSIsFuncAccessible',
          data:body,
          httpsAgent: httpsAgent,
          headers:{
              'signature': signature,
              'timestamp': timestamp
            },
        }).then(function (response) {
          console.log(response.data);
          if(!(response.data.isSuccess&&response.data.isAccessible)){
            history.push("/signin");
            customMessage.error("Not Accessible")
            return;
          }
      dispatch(
          userSlice.actions.setRefreshToken({
            refreshToken: response.data.refresh_token,
            token: response.data.access_token,
          }));
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
      history.push("/signin");
    });
    });
     setTimeout(() => {
       setLoginCheck(!loginCheck);
     }, apiconfig.loginCheckTimeoutSec*1000);
  }, [loginCheck]);


useEffect(() => {
  console.log("OnLoad2");
  if(accesstoken === null){
    history.push("/signin");
    return;
  }
},[accesstoken]);
  useEffect(() => {
    console.log("OnLoad");

    if(accesstoken === null){
      history.push("/signin");
      return;
    }
    setTimeout(() => {
      setLoginCheck(!loginCheck);
    }, apiconfig.loginCheckTimeoutSec*1000);
  }, []);
  return (
    <MainLayout siderComponent={null}>
    <Row><Col>
    <Menu
        onClick={(e)=>{
          //console.log("click ", e);
          if(e.key === "printing"){
            history.push("/PrinterLanding");
          }
          if(e.key === "payment"){
            history.push("/Payment");
          }
        }}
        selectedKeys={[current]}
        mode="horizontal"
        >
            <Menu.Item key="printing" icon ={<PrinterOutlined />} >Printing</Menu.Item>
            <Menu.Item key="payment" icon ={<FileTextOutlined />} >Payment</Menu.Item>
        </Menu>
    </Col></Row>
      <div className={styles["content"]}>
      <Title level={3} className={styles["content-title"]}>
        {title}
      </Title>
        <Switch>
          <Route exact path={path} component={PrinterList} />
        </Switch>

      </div>
    </MainLayout>
  );
};
