var aes256 = require('rijndael-js');
const padder = require('pkcs7-padding');


module.exports = {
  btoa :function(input){
    return Buffer.from(input).toString('base64')
  },
  atob :function (input){
    return Buffer.from(input,'base64').toString('utf8')
  },
  doAes :function (original,aesCipher,aesIV){
     return this.btoa(aesCipher.encrypt(padder.pad(original,16), 128, aesIV));
  },
  undoAes :function (crypted,aesCipher,aesIV){
    crypted = Buffer.from(crypted,'base64');
    return Buffer.from(aesCipher.decrypt(crypted, 128, aesIV)).toString('utf8');
  }
}
