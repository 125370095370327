import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios/custom-axios";
import jwtDecode from "jwt-decode";
import * as returnMessage from "../../constant/returnMessage";
import { rsaEncrypt } from "../../utils/crytoHelper";
import { getRsaPublic } from "../../utils/commonApi";
// import mockupData from "../../mockups.json"

interface UserState {
  loading: boolean;
  error: string | null;
  token: null | string;
  refreshToken: any;
  siteid:int|null;
}

const initialState: UserState = {
  loading: false,
  error: null,
  token: null,
  refreshToken: null,
  siteid:null,
};

export const signin = createAsyncThunk(
  "user/signin",
  async (
    // params: {
    //   username: string;
    //   password: string;
    // },
    // { rejectWithValue }
  ) => {
    // try {
    //   axios.defaults.withCredentials = true;
    //   const rsa_public_key = await getRsaPublic();
    //   const encrypted_password = rsaEncrypt(rsa_public_key, params.password);
    //   const { data } = await axios.post(`/user/v1/login`, {
    //     login_name: params.username,
    //     password: encrypted_password,
    //   });
    //   console.log(data);
    //   if (data.statusCode === returnMessage.SUCCESS.statusCode) {
    //     const {
    //       result: { access_token },
    //     } = data;
    //     axios.defaults.headers.common[
    //       "Authorization"
    //     ] = `Bearer ${access_token}`;
    //     const decodedToken = jwtDecode(access_token);
    //
    //     return { token: access_token, user: decodedToken };
    //   } else if (
    //     data.statusCode ===
    //     returnMessage.LOGIN_NAME_OR_PASSWORD_INVALID.statusCode
    //   ) {
    //     return rejectWithValue(
    //       returnMessage.LOGIN_NAME_OR_PASSWORD_INVALID.msg
    //     );
    //   } else {
    //     return rejectWithValue(returnMessage.SOMETHING_GET_WRONG.msg);
    //   }
    // } catch (err) {
    //   if (err.response) {
    //     console.log(err.response.data);
    //   } else if (err.request) {
    //     console.log(err.request);
    //   } else {
    //     console.log("Error", err.message);
    //   }
    //
    //   return rejectWithValue(returnMessage.SOMETHING_GET_WRONG.msg);
    return {}

  }
);

// export const refreshToken = createAsyncThunk(
//   "user/refreshToken",
//   async (_, thunkAPI) => {
//     try {
//       axios.defaults.withCredentials = true;
//       const { data } = await axios.get(
//         `http://localhost:3001/cms_service/user/v1/refresh_token`
//       );
//       console.log(data);
//       if (data.statusCode === 1000) {
//         const {
//           result: { access_token },
//         } = data;
//         axios.defaults.headers.common[
//           "Authorization"
//         ] = `Bearer ${access_token}`;
//         const decodedToken = jwtDecode(access_token);

//         return { token: access_token, user: decodedToken };
//       } else {
//         throw new Error("fail to refresh token");
//       }
//     } catch (err) {
//       if (err.response) {
//         console.log(err.response.data);
//       } else if (err.request) {
//         console.log(err.request);
//       } else {
//         console.log("Error", err.message);
//       }
//       throw new Error(err.message);
//     }
//   }
// );

export const logout = createAsyncThunk("user/logout", async (_, thunkAPI) => {
  // try {
    //const { data } = await axios.post(`/user/v1/logout`);
    //console.log(data);
    //todo: clear access token,refresh token
  // } catch (err) {
  //   if (err.response) {
  //     console.log(err.response.data);
  //   } else if (err.request) {
  //     console.log(err.request);
  //   } else {
  //     console.log("Error", err.message);
  //   }
  // }
  return {};
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.loading = false;
      state.error = null;
      state.refreshToken = null;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.loading = false;
      state.error = null;
    },
    setSite:(state,action)=>{
      state.siteid = action.payload.siteid;
      console.log(state);
    }
  },
  extraReducers: {
    [signin.pending.type]: (state) => {
      // return { ...state, loading: true };
      state.loading = true;
    },
    [signin.fulfilled.type]: (state, action) => {
      // state.userInfo = action.payload.user;
      // state.token = action.payload.token;
      state.loading = false;
      state.error = null;
    },
    [signin.rejected.type]: (state, action: PayloadAction<string | null>) => {
      //   const ddd = action.payload;
      state.loading = false;
      state.error = action.payload;
    },
    // [refreshToken.pending.type]: (state) => {
    //   state.loading = true;
    // },
    // [refreshToken.fulfilled.type]: (state, action) => {
    //   state.userInfo = action.payload.user;
    //   state.token = action.payload.token;
    //   state.loading = false;
    //   state.error = null;
    // },
    // [refreshToken.rejected.type]: (state, action: PayloadAction<string | null>) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    [logout.pending.type]: (state) => {
      state.loading = true;
    },
    [logout.fulfilled.type]: (state, action) => {
      state.token = null;
      state.loading = false;
      state.error = null;
      state.refreshToken = null;
    },
    [logout.rejected.type]: (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
