import "antd/dist/antd.less";
import React from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import styles from "./App.module.scss";
import {SigninPage} from "./pages";
//import { TransactionPage } from "./pages/transaction";
import { ICSSPrinterLanding } from "./pages/ICSSPrinterLanding";
import { ICSSPaymentLanding } from "./pages/ICSSPaymentLanding";

import {UserGroupDetail} from "./pages/userGroupPage/detail/UserGroupDetail";
import { useSelector } from "./redux/hook";
import { customMessage } from "./utils/common";


const PrivateRoute = ({ component, isAuthenticated, accessible, ...rest }) => {
  useEffect(() => {
    if (isAuthenticated && !accessible) {
      customMessage.info("No Permission");
    }
  }, [isAuthenticated, accessible]);

  const routeComponent = (props) => {
    return isAuthenticated
      ?
      accessible ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
      : (
        <Redirect to={{ pathname: "/signin" }} />
      );
  };
  return <Route render={routeComponent} {...rest} />;
};


function App() {
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.userInfo);
  // const checkPathAccessible = (path, is_write = false) => {
  //   return userInfo?.permission_list?.some(item => item.ui_path === path && (is_write ? item.permission_name === "write" : true))
  // };

  return (
    <div className={styles.App}>
      <Router basename="/">
        <Switch>
          {/* <PrivateRoute exact isAuthenticated={token !== null} accessible={true} path="/" component={ChargerPage} /> */}
          <Route path="/signin" component={SigninPage} />

          <Route
            exact
            path="/PrinterLanding"
            component={ICSSPrinterLanding}
          />
          <Route
            exact
            path="/Payment"
            component={ICSSPaymentLanding}
          />

          <Redirect to="/signin" />

        </Switch>
      </Router>
    </div>
  );
}

export default App;
