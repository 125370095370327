import axios from "../axios/custom-axios";
import { message } from "antd";
const axios1 = require('axios');
const apiconfig = window.apiconfig;
var apiHost = apiconfig.apihost;
var rasHost = apiconfig.rasHost;
const { aesEncryption, generateAESKey } = require("./aes");
const generateSignature =  require("./generateSignature");
const rsaEncryption = require("./rsa");
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});

export const callApi = async (url, method, data?) => {
  try {
    const { data: resData } = await axios({
      url,
      method,
      data,
      headers :{
        'Content-Type': 'text/plain'
      },
      withCredentials: false
    });
    if (resData.statusCode === 1000) {
      return resData.result;
    } else {
      throw new Error(resData.msg);
    }
  } catch (error) {
    throw error;
  }
};

// export const customMessage = (
//   type: "success" | "info" | "error" | "loading" | "warn" | "warning",
//   content: string
// ) => {
//   const key = Date.now();
//   const config = {
//     key,
//     content,
//     onClick: () => {
//       message.destroy(key);
//     },
//     duration: 2,
//   };
//   switch (type) {
//     case "success":
//       message.success(config);
//       break;
//     case "info":
//       message.info(config);
//       break;
//     case "error":
//       message.error(config);
//       break;
//     case "loading":
//       message.loading(config);
//       break;
//     case "warn":
//       message.warn(config);
//       break;
//     case "warning":
//       message.warning(config);
//       break;
//     default:
//       throw new Error("invalid type");
//       break;
//   }
// };

export class customMessage {
  static getConfig(content: string) {
    const key = Date.now();
    const config = {
      key,
      content,
      onClick: () => {
        message.destroy(key);
      },
      duration: 2,
    };
    return config;
  }
  static success(content: string) {
    message.success(this.getConfig(content));
  }
  static info(content: string) {
    message.info(this.getConfig(content));
  }
  static error(content: string) {
    message.error(this.getConfig(content));
  }
  static loading(content: string) {
    message.loading(this.getConfig(content));
  }
  static warn(content: string) {
    message.warn(this.getConfig(content));
  }
}

export const getEncBody = (input)=>{
 return axios1.get(rasHost+'/get_rsa_key',
     {httpsAgent: httpsAgent}
 )
 .then((response)=>{
     console.log('get rsa key success');
     return response.data.key;
 })
 .catch((error)=>{
     console.log(`get rsa key error: ${error}`)
     return null;
 }).then((rsa_key)=>{
   if (rsa_key === null){
     return;
   }
   var aeskey = generateAESKey();
    var encryption_body = aesEncryption(JSON.stringify(input), aeskey.key, aeskey.iv);
   //var encryption_body = JSON.stringify(input);
   var key = Buffer.from(rsa_key,'base64').toString();
   var ak = rsaEncryption(JSON.stringify({
       key:  aeskey.key.toString('base64'),
       iv: aeskey.iv.toString('base64')
   }),key)
   return  {
       ak: ak,
       payload: encryption_body
   }
 })
}
