var crypto = require('crypto');

function generateAESKey(){
    var key = crypto.randomBytes(32);
    var iv = crypto.randomBytes(16);

    return {key, iv};
}

function aesEncryption(data, key, iv){
    var cipherChunks = [];
    var cipher = crypto.createCipheriv('aes-256-cbc',Buffer.from(key),iv);
    cipher.setAutoPadding(true);
    cipherChunks.push(cipher.update(data, 'utf8', 'base64'));
    cipherChunks.push(cipher.final('base64'));
    return cipherChunks.join('');
}

function aesDecryption(data, key, iv){
    var cipherChunks = [];
    var decipher = crypto.createDecipheriv('aes-256-cbc',Buffer.from(key), iv);
    decipher.setAutoPadding(true);
    cipherChunks.push(decipher.update(data, 'base64', 'utf8'));
    cipherChunks.push(decipher.final('utf8'));
    return cipherChunks.join('');
}

module.exports = {
    generateAESKey,
    aesEncryption,
    aesDecryption
}