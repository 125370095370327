import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin
} from "antd";
import Title from "antd/lib/typography/Title";

import {
  DialogCom,
  CustomButton
} from "../../../components";
import resMessage from "../../../constant/returnMessage";
import { customMessage } from "../../../utils/common";
import styles from "./userGroupForm.module.scss";
const apiconfig = window.apiconfig;

const axios = require('axios')
const title = "Printer Test";


export const ICSSTestPrintInput = forwardRef(({ initialData, title, handleSubmit, submitLoading }: PropsType, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const [fetching, setFetching] = useState(true);

  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;

  const tagOptions = [
    {value: "VTT",label:"VTT" },{value: "TT",label:"TT" }]
  const classOptions = [
    {value: 1,label:"電單車及機動三輪車\nMotor Cycles and motor Tricycles (A)"},
  {value: 2,label:"私家車\nPrivate Car (B)"},
  {value: 3,label:"的士\nTAXI (C)"},
  {value: 4,label:"公共及私家小型巴士\nPublic and Private Light Bus (D)"},
  {value: 5,label:"輕型貨車\nLight Goods Vehicle (E)"},
  {value: 6,label:"中型貨車\nMedium Goods Vehicle (F)"},
  {value: 7,label:"重型貨車\nHeavy Goods Vehicle (G)"},
  {value: 8,label:"公共及私家單層巴士Public and Private Single-decked Bus (H)"},
  {value: 9,label:"雙層公共巴士及雙層私家巴士　Public Bus (Double-decked) and Private bus (Double-decked) (I)"}
]

  useImperativeHandle(ref, () => ({
    openDialog() {
      setVisible(!visible);
    },
    resetForm() {
      form.resetFields();
    }
  }));

  const CusTitle = () => {
    return <Title level={4}>{title}</Title>;
  };



  const onReset = () => {
    form.resetFields();
  };

      return (
        <div>
          <DialogCom
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            title={<CusTitle />}
          >
            <Form
              className={styles["form"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                tagType: "VTT",
                //vehicleClass:"Please select"
              }}
            >
              <Row gutter={1}>
                <Col span={9}>
                  <Form.Item
                    className={styles["form__item"]}
                    name="tagType"
                    label="Tag Type"
                    rules={[
                      {
                        required: true,
                        message: "Please type Tag Type",
                      },
                    ]}
                  >
                  <Select
                    className={styles["form__item-input"]}
                    mode="single"
                    placeholder="Please select"
                    options={tagOptions}
                    optionFilterProp="label"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    getPopupContainer={trigger => trigger.parentNode}
                  />
                  </Form.Item>
                </Col>
                <Col span={8}>

                </Col>
              </Row>

              <Col span={9}>
                <Form.Item
                  className={styles["form__item"]}
                  name="vehicleClass"
                  label="Vehicle Class"
                  rules={[
                    {
                      required: true,
                      message: "Please type Vehicle Class",
                    },
                  ]}
                >
                <Select
                  className={styles["form__item-input"]}
                  mode="single"
                  placeholder="Please select"
                  options={classOptions}
                  optionFilterProp="label"
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  getPopupContainer={trigger => trigger.parentNode}
                />
                </Form.Item>
              </Col>


              <Divider className={styles["form-divider"]} />
              <Row>
                <Form.Item>
                  <CustomButton
                    btn_size="medium"
                    type={"submit"}
                    btn_text="Print"
                    htmlType="submit"
                    loading={submitLoading}
                  ></CustomButton>
                </Form.Item>
              </Row>
            </Form>
          </DialogCom>
        </div>
      );
  })
