exports.SUCCESS = Object.freeze({
  statusCode: 1000,
  msg: "Operation is successful",
});

exports.INVALID_TOKEN = Object.freeze({
  statusCode: 2001,
  msg: "Invalid Token",
});

exports.INVALID_PARAMETERS = Object.freeze({
  statusCode: 2002,
  msg: "Invalid Parameters",
});

exports.SOMETHING_GET_WRONG = Object.freeze({
  statusCode: 2005,
  msg: "Something went wrong, please try again later",
});

exports.DB_CONNECTION_FAILED = Object.freeze({
  statusCode: 3001,
  msg: "DB Connection Failed",
});

exports.DB_ERROR = Object.freeze({
  statusCode: 3002,
  msg: "DB Errors",
});

exports.LOGIN_NAME_OR_PASSWORD_INVALID = Object.freeze({
  statusCode: 4001,
  msg: "Login Name or Password not Valid",
});

exports.USER_EXISTED = Object.freeze({
  statusCode: 4002,
  msg: "User Already Existed",
});

exports.USER_NOT_EXISTED = Object.freeze({
  statusCode: 4003,
  msg: "User Not Existed",
});

exports.NO_PERMISSION = Object.freeze({
  statusCode: 4011,
  msg: "No Premission",
});

exports.USER_GROUP_NOT_EXISTED = Object.freeze({
  statusCode: 4021,
  msg: "No User Group Found",
});

exports.USER_GROUP_EXISTED = Object.freeze({
  statusCode: 4022,
  msg: "User Group Already Existed",
});

exports.SITE_NOT_EXISTED = Object.freeze({
  statusCode: 4032,
  msg: "Site Does Not Exist",
});

exports.SITE_EXISTED = Object.freeze({
  statusCode: 4033,
  msg: "Site Already Existed",
});