import axiosInstance from "./custom-axios";
import { userSlice, logout } from "../redux/user/slice";
import jwtDecode from "jwt-decode";
import * as responseMessage from "../constant/returnMessage";

const setup = (store) => {
  // axiosInstance.interceptors.request.use(
  //   (config) => {
  //     const token = store.getState().user.token;
  //     if (token && !config._retry) {
  //       config.headers["Authorization"] = `Bearer ${token}`;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );
  //
  // const { dispatch } = store;
  // axiosInstance.interceptors.response.use(
  //   async (res) => {
  //     const originalConfig = res.config;
  //     // console.log(originalConfig._retry);
  //
  //     if (
  //       res.data.statusCode === responseMessage.DB_ERROR.statusCode &&
  //       !originalConfig._retry
  //     ) {
  //       originalConfig._retry = true;
  //       return new Promise((resolve, reject) => {
  //         setTimeout(() => {
  //           resolve(axiosInstance(originalConfig));
  //         }, 2000);
  //       });
  //     }
  //
  //     if (
  //       originalConfig.url !== "/user/v1/signin" &&
  //       originalConfig.url !== "/user/v1/refresh_token" &&
  //       originalConfig.url !== "/user/v1/logout"
  //     ) {
  //       if (
  //         res.data.statusCode === responseMessage.INVALID_TOKEN.statusCode &&
  //         !originalConfig._retry
  //       ) {
  //         originalConfig._retry = true;
  //
  //         try {
  //           const rs = await axiosInstance.get("/user/v1/refresh_token");
  //
  //           if (rs.data.statusCode === responseMessage.SUCCESS.statusCode) {
  //             const { access_token } = rs.data.result;
  //             const decodedToken = jwtDecode(access_token);
  //
  //             dispatch(
  //               userSlice.actions.refreshToken({
  //                 token: access_token,
  //                 user: decodedToken,
  //               })
  //             );
  //
  //             originalConfig.headers[
  //               "Authorization"
  //             ] = `Bearer ${access_token}`;
  //
  //             return axiosInstance(originalConfig);
  //           } else if (
  //             rs.data.statusCode === responseMessage.INVALID_TOKEN.statusCode
  //           ) {
  //             dispatch(logout());
  //           }
  //           return res;
  //         } catch (_error) {
  //           return Promise.reject(_error);
  //         }
  //       }
  //     }
  //     return res;
  //   },
  //   async (err) => {
  //     return Promise.reject(err);
  //   }
  // );
};

export default setup;
