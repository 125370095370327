import React, { ReactChild } from "react";
import styles from "./MainLayout.module.scss";

import { Col, Layout, Row, BackTop } from "antd";
import { Header } from "../../components";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";

interface PropsType {
  siderComponent: ReactChild | null;
}

export const MainLayout: React.FC<PropsType> = (props) => {
  let haveSide = props.siderComponent ? true : false;

  return (
    <>
      <Layout className={styles["layout"]}>
        <Header />
        <Layout.Content className={styles["page-content"]}>
          <Row style={{ height: "100%" }}>
            <Col span={haveSide ? 4:0}>{props.siderComponent}</Col>
            <Col span={haveSide ? 20 : 24}>
              <div className={styles["content"]}>
                <Breadcrumbs />
                {props.children}
              </div>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <BackTop />
    </>
  );
};
