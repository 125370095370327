export const MenuData = [
  //{
  //   key: "1",
  //   title: "Charger",
  //   path: "/charger",
  //   icon: "",
  //   children: [
  //     {
  //       key: "1_1",
  //       title: "Charger",
  //       path: "/charger",
  //       icon: "",
  //     },
  //     {
  //       key: "1_2",
  //       title: "Terminal",
  //       path: "/terminal",
  //       icon: "",
  //     },
  //     {
  //       key: "1_3",
  //       title: "Payment Group",
  //       path: "/paymentgroup",
  //       icon: "",
  //     },
  //     {
  //       key: "1_4",
  //       title: "Charger Monitor",
  //       path: "/chargermonitor",
  //       icon: "",
  //     },
  //   ],
  // },
  // {
  //   key: "2",
  //   title: "Transaction",
  //   path: "/transaction",
  //   icon: "",
  //   children: [
  //     {
  //       key: "2_1",
  //       title: "Normal Transaction",
  //       path: "/transaction/nortxn",
  //       icon: "",
  //       permit_path: "/transaction",
  //     },
  //     {
  //       key: "2_2",
  //       title: "Abnormal Transaction",
  //       path: "/transaction/abnortxn",
  //       icon: "",
  //       permit_path: "/transaction",
  //     },
  //   ],
  // },
  // {
  //   key: "3",
  //   title: "Promotion",
  //   path: "/promotion",
  //   icon: "",
  // },
  // {
  //   key: "4",
  //   title: "Monthly Pass Plan",
  //   path: "/monthlyPassPlan",
  //   icon: "",
  //   children: [
  //     {
  //       key: "4_1",
  //       title: "Monthly Pass Plan",
  //       path: "/monthlyPassPlan",
  //       icon: "",
  //       permit_path: "/monthlyPassPlan",
  //     },
  //     {
  //       key: "4_2",
  //       title: "Payment Group",
  //       path: "/monthlyPassPlan/monthlyPassPlanPaymentGroup",
  //       icon: "",
  //       permit_path: "/monthlyPassPlan",
  //     },
  //     {
  //       key: "4_3",
  //       title: "Member Monthly Pass Plan",
  //       path: "/monthlyPassPlan/memberMonthlyPassPlan",
  //       icon: "",
  //       permit_path: "/monthlyPassPlan",
  //     },
  //   ],
  // },
  // {
  //   key: "5",
  //   title: "Notification",
  //   path: "/notification",
  //   icon: "",
  // },
  // {
  //   key: "6",
  //   title: "User Group",
  //   path: "/userGroup",
  //   icon: "",
  // },
  // {
  //   key: "7",
  //   title: "User",
  //   path: "/user",
  //   icon: "",
  // },
  // {
  //   key: "8",
  //   title: "Site",
  //   path: "/site",
  //   icon: "",
  // },
];
