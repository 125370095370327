import Icon, {
  CaretDownOutlined, UserOutlined
} from "@ant-design/icons";
import {
  Avatar, Col,
  Dropdown, Layout,
  Menu,
  Row, Space,
  Typography
} from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MenuData } from "../../mockup/menuList";
import { useSelector } from "../../redux/hook";
import { logout } from "../../redux/user/slice";
import styles from "./Header.module.scss";
import { UserCard } from "./userCard";

const SubMenu = Menu.SubMenu;

export const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  //const permission_list = useSelector(state => state.user.userInfo?.permission_list);

  const createMenu = (menuData) => {
    let submenuIndex = 0;
    let menu = [];
    // if (!permission_list) {
    //   return [];
    // }
    const create = (menuData, el, isChild = false) => {
       for (let i = 0; i < menuData.length; i++) {
      //   if (!permission_list.some(item => item.ui_path === (menuData[i].permit_path ? menuData[i].permit_path : menuData[i].path))) {
      //     continue;
      //   }

        if (menuData[i].children) {
          let children = [];
          create(menuData[i].children, children, true);
          submenuIndex++;
          // console.log(`SubMenu key: sub${submenuIndex}_${menuData[i].title}`);
          if (children.length > 0) {
            el.push(
              <SubMenu
                className={styles["app-submenu"]}
                key={`sub${submenuIndex}_${menuData[i].title}`}
                title={
                  <span>
                    {menuData[i].icon ? <Icon type={menuData[i].icon} /> : null}
                    {menuData[i].title}
                  </span>
                }
              >
                {children}
              </SubMenu>
            );
          }
        } else {
          // console.log(`item key: ${menuData[i].path}`);
          el.push(
            <Menu.Item
              key={menuData[i].path}
              title={menuData[i].title}
              className={styles["app-menu__item"]}
            >
              <Link to={menuData[i].path}>
                <span className={styles["app-menu__item-text"]}>
                  {menuData[i].icon ? <Icon type={menuData[i].icon} /> : null}
                  {menuData[i].title}
                </span>
              </Link>
            </Menu.Item>
          );
        }
      }
    };

    create(menuData, menu);
    return menu;
  };

  const onLogout = () => {
    dispatch(logout());
    history.push("/signin");
  };

  // console.log(location.pathname);

  return (
    <>
      <Layout.Header className={styles["app-header"]}>
        <Row>
          <Col span={4}>
            <div className={styles["left-header"]}></div>
            {/* <img src={logo} alt="logo" className={styles["App-logo"]} /> */}
            <Typography.Title level={3} className={styles["left-header-title"]}>
              {`ICSS CMS`}
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Menu className={styles["app-menu"]} mode="horizontal"
              selectedKeys={[MenuData.find(d => d.path === location.pathname || d.children?.find(c => c.path === location.pathname)) ? location.pathname : "/" + location.pathname.split("/")[1]]}
            >
              {createMenu(MenuData)}
            </Menu>
            {/* <Menu
              className={styles["app-menu"]}
              mode="horizontal"
              defaultSelectedKeys={["1"]}
            >
              <Menu.Item
                className={styles["app-menu__item"]}
                key="general"
                onClick={() => setSubMenu(!subMenu)}
              >
                <span className={styles["app-menu__item-text"]}>General</span>
              </Menu.Item>
            </Menu> */}
          </Col>
          <Col span={2}>
            <Dropdown placement="bottomRight" overlay={UserCard(onLogout)}>
              <Space>
                <Avatar
                  className={styles["avatar"]}
                  icon={<UserOutlined />}
                  gap={0}
                />
                <CaretDownOutlined className={styles["avatar-icon"]} />
              </Space>
            </Dropdown>
          </Col>
        </Row>
      </Layout.Header>
    </>
  );
};
