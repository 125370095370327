import React from "react";
import { UserLayout } from "../../layouts";
import { SigninForm } from "./SigninForm";

export const SigninPage: React.FC = () => {
  return (
    <UserLayout>
      <SigninForm />
    </UserLayout>
  );
};
