import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin
} from "antd";
import Title from "antd/lib/typography/Title";

import {
  DialogCom,
  CustomButton
} from "../../../components";
import resMessage from "../../../constant/returnMessage";
import { customMessage } from "../../../utils/common";
import styles from "./userGroupForm.module.scss";
const apiconfig = window.apiconfig;

const axios = require('axios')
const title = "";


export const ICSSCancelJobInput = forwardRef(({ initialData, title, handleSubmit, submitLoading }: PropsType, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const [fetching, setFetching] = useState(true);

  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;

  useImperativeHandle(ref, () => ({
    openDialog() {
      setVisible(!visible);
    },
    resetForm() {
      form.resetFields();
    }
  }));

  const CusTitle = () => {
    return <Title level={4}>{title}</Title>;
  };

console.log(initialData);

  const onReset = () => {
    form.resetFields();
  };

      return (
        <div>
          <DialogCom
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            title={<CusTitle />}
          >
            <Form
              className={styles["form"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                job_id: [],
                //vehicleClass:"Please select"
              }}
            >
              <Row gutter={1}>
              Cancel Job:{initialData.join(',')}
              </Row>

              <Divider className={styles["form-divider"]} />
              <Row>
                <Form.Item>
                  <CustomButton
                    btn_size="medium"
                    type={"submit"}
                    btn_text="Confirm"
                    htmlType="submit"
                    loading={submitLoading}
                  ></CustomButton>
                </Form.Item>
              </Row>
            </Form>
          </DialogCom>
        </div>
      );
  })
