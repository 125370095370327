import { Col, Form, Input, Row, Typography, Image, Button,  Select,
  Spin} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CustomButton } from "../../components";
import { useWindowSize } from "../../hook/useWindowSize";
import { useSelector } from "../../redux/hook";
import { signin } from "../../redux/user/slice";
import styles from "./SigninForm.module.scss";
import { userSlice } from "../../redux/user/slice";
import { customMessage, getEncBody } from "../../utils/common";
const commonLib = require('../../utils/TestCommon.js');
const { aesEncryption, generateAESKey } = require("../../utils/aes");
var moment = require('moment');
const nodeRSA = require('node-rsa');
var aes256 = require('rijndael-js');
const axios = require('axios');
const https = require('https');
const apiconfig = window.apiconfig;
const httpsAgent = new https.Agent({rejectUnauthorized:false});
const generateSignature =  require("../../utils/generateSignature");
const rsaEncryption = require("../../utils/rsa");
var rasHost = apiconfig.rasHost;
var apiHost = apiconfig.apihost;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 24 },
};

export const SigninForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((s) => s.user.loading);
  const error = useSelector((s) => s.user.error);
  const jwt = useSelector((s) => s.user.token);
  const siteid = useSelector((s) => s.user.siteid);
  var [login, setLogin] = useState(false);
  const windowSize = useWindowSize();
  // var [siteOptions,setSiteOptions] = useState([{value: 0,label:"Site" }]);
  //var apiHost = "http://localhost:49162";

  var client_sercet = apiconfig.client_sercet;
  useEffect(() => {
    if (login) {
      history.push("/PrinterLanding");
    }
  }, [login, history]);


  const onFinish = async (values: any) => {
    var aeskey = "key"+moment().Unix
    aeskey = aeskey.padStart(24,"123456");
    var aesIV = ("ICSS"+moment().Unix).padStart(16,"123456");
    var aesCipher = new aes256(aeskey, 'cbc');


    var body = await getEncBody({});
    var timestamp = new Date(Date.now()).toISOString()
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSGetEncryptKey")
    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSGetEncryptKey',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then( function (response) {

      let _raskey= commonLib.atob(response.data.rsaKey);
      console.log(_raskey);
      let key = new nodeRSA(_raskey);
        key.setOptions({encryptionScheme :'pkcs1'});
      let aesRijKey = commonLib.btoa(commonLib.btoa(aesIV)+','+commonLib.btoa(aeskey));
      console.log(`AESKey:${aesRijKey}`);

      getEncBody({
        "LoginID":values.username,
        "Password": commonLib.doAes(values.password,aesCipher,aesIV),
        "LoginID":"icssadmin",
        // "Password": commonLib.doAes("Autotoll.1234",aesCipher,aesIV),
         "decryptKey":key.encrypt(aesRijKey,'base64'),
      }).then((body)=>{
        var timestamp = new Date(Date.now()).toISOString()
        var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSLogin")

        console.log(body);
        axios({
          method: 'post',
          url: apiHost+'/ICSSCMSLogin',
          data:body,
          httpsAgent: httpsAgent,
                headers:{
                    'signature': signature,
                    'timestamp': timestamp
                }
        }).then(function (response) {
          console.log(response.data);
          //console.log("Success:", values);

          setLogin(true);
          dispatch(
            userSlice.actions.setSite({
              siteid: null,
            }));
            dispatch(
            userSlice.actions.setRefreshToken({
              refreshToken: response.data.refresh_token,
              token: response.data.access_token,
            }));
        }).catch(err=>{
            console.log(err);
            customMessage.error(err.message)
          });;
      }).catch(err=>{
          console.log(err);
          customMessage.error(err.message)
        });
      }).catch(err=>{
          console.log(err);
          customMessage.error(err.message)
        });


  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [fetching, setFetching] = useState(true);
  return windowSize.type === "small" ? (
    <Row className={styles["description"]}>
      <Col className={styles["description__content"]} span={24}>
        <Typography.Title
          level={2}
          className={styles["description__content-logo"]}
        >
        </Typography.Title>
        <Typography.Title
          level={3}
          className={styles["description__content-title"]}
        >
          ICSS
        </Typography.Title>
        <Typography.Title
          level={5}
          className={styles["description__content-description"]}
        >
          <br />
          CMS
        </Typography.Title>
        <div className={styles["description__content-version"]}>v1.0</div>
      </Col>
      <Col className={styles["signin-form"]} span={24}>
        <Typography.Title level={2} className={styles["signin-form-title"]}>
          SIGN IN
        </Typography.Title>

        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={styles["sign-form"]}
          hideRequiredMark={true}
        >
          <Form.Item
            label="USERNAME"
            name="username"
            className={styles["signin-form__item"]}
            rules={[{ required: true, message: "Please input username" }]}
          >
            <Input
              size={"small"}
              className={styles["signin-form__input"]}
              placeholder={"User Name"}
            />
          </Form.Item>

          <Form.Item
            label="PASSWORD"
            name="password"
            className={styles["signin-form__item"]}
            rules={[{ required: true, message: "Please input password" }]}
          >
            <Input.Password
              className={styles["signin-form__input"]}
              size={"middle"}
              placeholder={"Password"}
            />
          </Form.Item>

          <Form.Item className={styles["signin-form__item"]}>
            <CustomButton
              loading={loading}
              htmlType="submit"
              block
              type={"normal"}
              btn_text={"SIGN IN"}
            ></CustomButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  ) : (
    <Row className={styles["description"]}>
      <Col className={styles["description__content"]} span={14}>
        <Typography.Title
          level={2}
          className={styles["description__content-logo"]}
        >
        </Typography.Title>
        <Typography.Title
          level={3}
          className={styles["description__content-title"]}
        >
          ICSS
        </Typography.Title>
        <Typography.Title
          level={5}
          className={styles["description__content-description"]}
        >
          <br />
         CMS
        </Typography.Title>
        <div className={styles["description__content-version"]}>v1.0</div>
      </Col>
      <Col className={styles["signin-form"]} span={10}>
        <Typography.Title level={2} className={styles["signin-form-title"]}>
          SIGN IN
        </Typography.Title>

        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={styles["sign-form"]}
          hideRequiredMark={true}
        >
          <Form.Item
            label="USERNAME"
            name="username"
            className={styles["signin-form__item"]}
            rules={[{ required: true, message: "Please input username!" }]}
          >
            <Input
              size={"large"}
              className={styles["signin-form__input"]}
              placeholder={"User Name"}
            />
          </Form.Item>

          <Form.Item
            label="PASSWORD"
            name="password"
            rules={[{ required: true, message: "Please input password!" }]}
          >
            <Input.Password
            size={"large"}
            placeholder={"Password"}
            />
          </Form.Item>

          <Form.Item className={`${styles["signin-form__item"]} ${styles["error"]}`}>
            {error}
          </Form.Item>

          <Form.Item className={styles["signin-form__item"]}>
            <CustomButton
              loading={loading}
              htmlType="submit"
              block
              type={"normal"}
              btn_text={"SIGN IN"}
            ></CustomButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
