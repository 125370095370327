import { Modal } from "antd";
import React, { ReactNode } from "react";

interface CollectionCreateFormProps {
  visible: boolean;
  onCancel: () => void;
  title?: string | ReactNode;
  width?: string | number | any | undefined;
}

export const DialogCom: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCancel,
  title,
  width,
  children,
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      okText="Create"
      destroyOnClose={true}
      style={{
        borderRadius: "4px",
        minHeight: "10rem",
        minWidth: "40rem",
        maxWidth: "106.667rem",
      }}      
      centered
      width={width ? width : "60rem"}
      cancelText="Cancel"
      onCancel={onCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};
