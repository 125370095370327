import React from 'react';
import { Drawer, List } from 'antd';



interface AppProps {
  placement,
  parkingLot,
  visible,
  onClose,
  data: Array<any>,
}

export class EvDrawer extends React.PureComponent<AppProps> {  

  render() {
    let data = this.props.data.map((row) => {
      return `${row.created_time} [${row.status}] ${row.error_message}`
    });   
    return (
      <>
        <Drawer height='50vh'
          title={`${this.props.parkingLot} Event Logs`}
          placement={this.props.placement}
          closable={true}
          onClose={this.props.onClose}
          visible={this.props.visible}
          key={this.props.placement}
        >
          <List
            size="large"
            bordered
            dataSource={data}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </Drawer>
      </>
    );
  }
}
