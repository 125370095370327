import { DatePicker, Form, Input, Select } from "antd";
import React from "react";
import { CustomButton, IconArrow, IconCalendar } from "../../components";
import styles from "./sideForm.module.scss";
const { Option } = Select;

export const NormalForm = () => {
  return (
    <>
      <div className={styles["form-headerBox"]}>
        <span className={styles["form-headerBox__text"]}>380 Result</span>
      </div>
      <Form.Item
        label="Start Date"
        name="start_date"
        className={styles["form__item"]}
      >
        <DatePicker
          placeholder="Select"
          className={styles["form__item-input"]}
          suffixIcon={<IconArrow />}
        />
      </Form.Item>
      <Form.Item
        label="End Date"
        name="end_date"
        className={styles["form__item"]}
      >
        <DatePicker
          className={styles["form__item-input"]}
          placeholder="Select"
          suffixIcon={<IconCalendar />}
        />
      </Form.Item>
      <Form.Item label="Label" name="label" className={styles["form__item"]}>
        <Input
          bordered={false}
          className={styles["form__item-input"]}
          placeholder={"Type in"}
        />
      </Form.Item>
      <Form.Item label="Label" name="label1" className={styles["form__item"]}>
        <Select
          bordered={false}
          className={styles["form__item-input"]}
          placeholder={"Select"}
          suffixIcon={<IconArrow />}
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Label" name="label2" className={styles["form__item"]}>
        <Select
          bordered={false}
          className={styles["form__item-input"]}
          placeholder={"Select"}
          suffixIcon={<IconArrow />}
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Label" name="label3" className={styles["form__item"]}>
        <Select
          bordered={false}
          className={styles["form__item-input"]}
          placeholder={"Select"}
          suffixIcon={<IconArrow />}
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
      </Form.Item>
      <div className={"form_item-submit"}>
        <CustomButton
          type={"normal"}
          htmlType="submit"
          btn_text="APPLY"
          width="100%"
          btn_size="large"
        />
      </div>
    </>
  );
};
