import { ApiTwoTone, CheckCircleTwoTone, CloseCircleTwoTone, CodeTwoTone, DollarTwoTone, ExclamationCircleOutlined, HourglassTwoTone, PauseCircleTwoTone, ProfileTwoTone, ThunderboltTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Modal, Space, Tooltip,Select,Spin,Table, Row, Col } from "antd";
import dateFormat from 'dateformat';
import React, { Fragment, useCallback, useEffect,  useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CustomTable, CustomButton } from "../../../components";
import { useWritePermissionChecking } from "../../../hook/useWritePermissionChecking";
import { callApi, customMessage, getEncBody } from "../../../utils/common";
import ExportExcel from "../../../utils/exportExcel";
import styles from "./transactionList.module.scss";
import { ICSSPaymentCreate } from "./ICSSPaymentCreate";
import { ICSSPaymentUpdate } from "./ICSSPaymentUpdate";

import { useSelector } from "../../../redux/hook";
import { useDispatch } from "react-redux";
import { userSlice } from "../../../redux/user/slice";
const apiconfig = window.apiconfig;
var rasHost = apiconfig.rasHost;
var client_sercet = apiconfig.client_sercet
const { aesEncryption, generateAESKey } = require("../../../utils/aes");
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});
const generateSignature = require("../../../utils/generateSignature");
const rsaEncryption = require("../../../utils/rsa");
const axios = require('axios')

const sqlDateFormat = "DD-MM-yyyy HH:mm:ss";

export const PaymentList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [data, setData] = useState<any[]>([]);
  const iCSSPaymentCreate_childRef = useRef();
  const iCSSPaymentUpdate_childRef = useRef();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [select, setSelect] = useState({
    selectedRowKeys: []
  });
  const { selectedRowKeys } = select;
  const [selectData,setSelectData] = useState([]);

  const [paymentData,setPaymentData] = useState([]);
  const [paymentOption,setPaymentOption] = useState(0);
  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;
  let columns = [
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      fixed: "left",
      width: 150
    },
    {
      title: "Name",
      dataIndex: "vc_name",
    },
    {
      title: "Enable",
      dataIndex: "enabled",
    },
    {
      title: "Effective From",
      dataIndex: "effective_from",
    },
  ];

  const paymentListOption=[{name:'All Payment',value :0},{name:'Enabled',value :1}];
  const getPayments = useCallback( async () =>{

    var body = await getEncBody({
      mode : paymentOption,
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSVendingPaymentQuery");
    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSVendingPaymentQuery',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      setPaymentData(response.data.paymentList.map((payment)=>{payment.enabled = payment.enabled===0?'false':'true';return payment; }).map((payment)=>({key:payment.paymentId,...payment})))
      console.log(paymentData);

    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    });
  },[paymentOption])

  const handleCreatePayment =  async (values) => {
    console.log(`values`, values);
    setSubmitLoading(true);
    var body = await getEncBody({
        vc_name : values.vc_name,
        effective_from : values.effective_from.format(sqlDateFormat),
      });
    var timestamp = new Date(Date.now()).toISOString()
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSVendingPaymentCreate")

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSVendingPaymentCreate',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      getPayments();
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    }).finally(()=>{
      openDialog("create");
      setSubmitLoading(false);
    });
  };

  const handleUpdatePayment =  async (values) => {
    console.log(`values`, values);
    console.log(selectedRowKeys[0]);
    setSubmitLoading(true);
    var body = await getEncBody({
      paymentId : selectedRowKeys[0],
      enabled : values.enabled?1:0,
      effective_from:values.effective_from.format(sqlDateFormat)
    });
    var timestamp = new Date(Date.now()).toISOString()
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSVendingPaymentUpdate")


    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSVendingPaymentUpdate',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      getPayments();
      if(response.data.result.code != 0){
        customMessage.error(response.data.result.sys_message)
      }else{
        iCSSPaymentUpdate_childRef.current.openDialog();
        customMessage.success("Success");
      }
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
      iCSSPaymentUpdate_childRef.current.openDialog();
    }).finally(()=>{
      setSubmitLoading(false);
    });
  };
  // const handleDisablePayment =  (values) => {
  //   console.log(`values`, values);
  //
  //   setSubmitLoading(true);
  //   var input = {
  //       paymentId : selectData[0].paymentId,
  //   }
  //   axios({
  //     method: 'post',
  //     url: apiHost+'/ICSSCMSVendingPaymentDisable',
  //     data:input
  //   }).then(function (response) {
  //     console.log(response.data);
  //     getPayments();
  //   }).catch(err=>{
  //     console.log(err);
  //     customMessage.error(err.message)
  //   }).finally(()=>{
  //     //openDialog("create");
  //     setSubmitLoading(false);
  //   });
  // };
  //
  // useEffect(() => {
  //   getPrinters()
  //   const timer = setInterval(getPrinters, 10000)
  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [getPrinters]);

  useEffect(() => {
    getPayments();
  }, [paymentOption]);
  const openDialog = (type) => {
    let amv: any;
    switch (type) {
       case "create":
         amv = iCSSPaymentCreate_childRef.current;
         amv.openDialog();
       break;
      case "update":
        if(selectData.length >0){
          amv = iCSSPaymentUpdate_childRef.current;
          amv.openDialog();
        }else{
          Modal.warning({
            title: 'Please select a payment',
          });
        }
        break;
      // case "remove` ":
      //   //amv = removePrinter_childRef.current;
      //   //if(selectData.length >0){
      //   //   amv.openDialog();
      //   //}
      //   break;
      default:
        break;
    }

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log('selectedRows: ', selectedRows);
      setSelect({
        ...select,
        selectedRowKeys: selectedRowKeys
      });
      setSelectData(selectedRows);
    },
  };
  return (
    <>
    <Row> <Col span={16}>  <div className={styles["btngroup"]}>

        <CustomButton
          onClick={() => openDialog("create")}
          icon="new"
          btn_size={"medium"}
          type={"normal"}
          btn_text={"Create Payment"}
        />
        <CustomButton
          onClick={() => openDialog("update")}
          icon="edit"
          btn_size={"medium"}
          type={"normal"}
          btn_text={"Update Payment"}
        />
        {/*
          <CustomButton
            onClick={() => {
              Modal.confirm({
                title: 'Do you want to remove this Printer?',
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                  handleRemovePrinter();
                },
                content: ``
                ,
                okText: 'Yes',
                cancelText: 'No',
              });
            }}
            icon="remove"
            btn_size={"medium"}
            type={"normal"}
            btn_text={"Disable Payment"}
          />

          */}

      </div></Col> <Col  span={8} > <Row justify="end"><Select

        placeholder="All Payment"
        className={styles["form__item-input"]}
        onChange={(value)=>{
          //console.log(value);
          setPaymentOption(value);
        }}
      >
        { paymentListOption.map((val)=>{
          return <Select.Option key={val.value} value={val.value}>{val.name}</Select.Option>;
        })}
      </Select> </Row></Col> </Row>

      <Table
        columns={columns}
        dataSource={paymentData}
        onRow={(record, rowIndex) => {
          return {
            onClick:() => {
              //console.log("clicked:"+rowIndex);
            }
          }
        }}
         rowSelection={{
           type: "radio",
           ...rowSelection
         }}
        loading={false}
      ></Table>
      <ICSSPaymentCreate ref={iCSSPaymentCreate_childRef} initialData={null} title="Create Payment" handleSubmit={handleCreatePayment} submitLoading={submitLoading} />
      <ICSSPaymentUpdate ref={iCSSPaymentUpdate_childRef} initialData={null} title="Update Payment" handleSubmit={handleUpdatePayment} submitLoading={submitLoading} />

  </>);
};
