import { UserOutlined } from "@ant-design/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Avatar, Card, Menu } from "antd";
import Meta from "antd/lib/card/Meta";
import { useHistory } from "react-router-dom";
import { useSelector } from "../../redux/hook";
import styles from "./userCard.module.scss";

export const UserCard = (onLogout) => {
  const history = useHistory();
  const username = useSelector(s => s.user?.userInfo?.login_name);
  const description = (
    <div>
      <span>{username}</span>
      <br />
      <span
        onClick={() => {
          history.push("/account");
        }}
        className={styles["description-changepass"]}
      >
        change pass
      </span>
    </div>
  );

  return (
    <Menu className={styles["usermenu"]}>
      <Menu.Item className={styles["dropdown-user"]}>
        <Card bordered={false} className={styles["dropdown-user"]}>
          <Meta
            avatar={
              <Avatar
                size={"large"}
                className={styles["avatar"]}
                icon={<UserOutlined />}
              />
            }
            description={description}
          />
        </Card>
      </Menu.Item>
      <Menu.Item onClick={onLogout} className={styles["dropdown__logout"]}>
        <ExitToAppIcon className={styles["dropdown__logout-icon"]} />
        <span className={styles["dropdown__logout-text"]}>Logout</span>
      </Menu.Item>
    </Menu>
  );
};
