import { Breadcrumb } from "antd";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import icon_arrow from "../../assets/icons/icon_arrow.png";
import styles from "./breadcrumbs.module.scss";

const breadcrumbNameMap = {
  "/userGroup": "User Group",
  "/userGroup/add": "Add",
  "/userGroup/edit": "Edit",
  "/userGroup/detail": "Detail",
  "/user": "User",
  "/user/add": "Add",
  "/user/edit": "Edit",
  "/user/detail": "Detail",
  // "/site": "Site",
  "/charger": "Charger",
  "/charger/add": "Add",
  "/charger/edit": "Edit",
  "/charger/detail": "Detail",
  "/terminal": "Terminal",
  "/terminal/add": "Add",
  "/terminal/edit": "Edit",
  "/terminal/detail": "Detail",
  "/paymentgroup": "Payment Group",
  "/paymentgroup/add": "Add",
  "/paymentgroup/edit": "Edit",
  "/paymentgroup/detail": "Detail",
  "/transaction/nortxn": "Normal Transaction",
  "/transaction/abnortxn": "Abnormal Transaction",
  "/promotion": "Promotion",
  "/promotion/add": "Add",
  "/promotion/edit": "Edit",
  "/promotion/detail": "Detail",
  "/monthlyPassPlan": "Monthly Pass Plan",
  "/monthlyPassPlan/add": "Add",
  "/monthlyPassPlan/edit": "Edit",
  "/monthlyPassPlan/detail": "Detail",
  "/monthlyPassPlan/monthlyPassPlanPaymentGroup":"Payment Group",
  "/monthlyPassPlan/monthlyPassPlanPaymentGroup/add":"Add",
  "/monthlyPassPlan/memberMonthlyPassPlan": "Member Monthly Pass Plan",
  "/notification": "Notification",
  "/notification/add": "Add",
  "/notification/detail": "Detail",
};

export const Breadcrumbs = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.length > 1 ?
    pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const checked = breadcrumbNameMap[url] ? true : false;
      return !checked ? null : (
        <Breadcrumb.Item
          key={url}
          className={styles["breadcrumb"]}
          separator={
            <img className={styles["breadcrumb-separator"]} src={icon_arrow} alt="" />
          }
        >
          <Link to={/(detail)|(edit)/.test(url) ? `${url}/${id}` : url}>{breadcrumbNameMap[url]}</Link>
          {/* <Link to={url}>{url}</Link> */}
        </Breadcrumb.Item>
      );
    })
    : null;
  const breadcrumbItems = extraBreadcrumbItems?.length && extraBreadcrumbItems.filter(i => i).length > 1 ? extraBreadcrumbItems : null;

  return (
    <Breadcrumb
      separator={
        <img className={styles["breadcrumb-separator"]} src={icon_arrow} alt="" />
      }
    >
      {breadcrumbItems}
    </Breadcrumb>
  );
};
