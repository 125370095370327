import * as React from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./customtable.module.scss";

interface AppState {
  searchText: string;
  searchedColumn: string;
}

interface AppProps {
  rowSelection?: any
  onRow?: any
  columns: Array<any>
  data: Array<any>
  searchCol?: Array<any>
  loading: boolean
  components?: any
  pagination?: any
  newline?: any
}

interface Column {
  title: string
  dataIndex: string
}

export class CustomTable extends React.Component<AppProps, AppState> {
  state = {
    searchText: "",
    searchedColumn: ""
  };
  searchInput: any;

  getColumnSearchProps = (c: Column) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${c.title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, c.dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, c.dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#D07902" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[c.dataIndex]
        ? JSON.stringify(record[c.dataIndex])
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text: string, record: any, index: any) => {
      return record[c.dataIndex];
    }
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let columns = this.props.columns.map((c) => {
      if (!this.props.searchCol?.includes(c.dataIndex)) {
        return c
      }
      return { ...this.getColumnSearchProps(c), ...c }
    });
    return <Table
      style={this.props.newline?{ whiteSpace: 'pre'}:undefined}
      className={styles["custom_table"]}
      rowSelection={this.props.rowSelection?{
        type: "checkbox",
        ...this.props.rowSelection,
      }:undefined}
      bordered={false}
      size="middle"
      dataSource={this.props.data}
      columns={columns}
      pagination={{
        position: ["bottomLeft"],
        defaultPageSize: 10,
        showSizeChanger: true,
        ...this.props.pagination
      }}
      scroll={{x:1500, y: "600px" }}
      onRow={this.props.onRow}
      loading={this.props.loading}
      components={this.props.components}
    ></Table>
  }
}
