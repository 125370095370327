import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin
} from "antd";
import Title from "antd/lib/typography/Title";

import {
  DialogCom,
  CustomButton
} from "../../../components";
import resMessage from "../../../constant/returnMessage";
import { customMessage, getEncBody } from "../../../utils/common";
import styles from "./userGroupForm.module.scss";
const apiconfig = window.apiconfig;
const { aesEncryption, generateAESKey } = require("../../../utils/aes");
var generateSignature = require("../../../utils/generateSignature");
const rsaEncryption = require("../../../utils/rsa");
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});
const axios = require('axios')
const title = "Printer Detail";


export const ICSSPrinterFormInput = forwardRef(({ initialData, title, handleSubmit, submitLoading }: PropsType, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [siteList, setsiteList] = useState({});
  const [fetching, setFetching] = useState(true);
  var [siteOptions,setSiteOptions] = useState([{value: 0,label:"Site" }]);
  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;
  var rasHost = apiconfig.rasHost;
  var client_sercet = apiconfig.client_sercet;


  useImperativeHandle(ref, () => ({
    openDialog() {
      setVisible(!visible);
    },
    resetForm() {
      form.resetFields();
    }
  }));

  const CusTitle = () => {
    return <Title level={4}>{title}</Title>;
  };

  useEffect(() => {
    if (visible) {
      getSites();
    }
  }, [visible]);

  const onReset = () => {
    form.resetFields();
  };


  const getSites = async () => {
    setFetching(true);

    var body = await getEncBody({});
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSQuerySiteList");
    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSQuerySiteList',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      var siteopt = [];
      response.data.siteList.forEach(element => {
        siteopt.push({value:element.site_id,label:element.site_name});
      });
      console.log(siteopt);
      if(siteopt.length > 0)
        setSiteOptions(siteopt);
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    });
  };

//console.log(initialData);

      return (
        <div>
          <DialogCom
            visible={visible}
            onCancel={() => {
              setsiteList(null);
              setVisible(false);
            }}
            title={<CusTitle />}
          >
            <Form
              className={styles["form"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                printer_name: initialData?.[0]?.printer_name??"",
                site_id:initialData?.[0]?.site_id??0,
                printer_ip:initialData?.[0]?.ip??"",
                printer_id:initialData?.[0]?.printer_id??0
              }}
            >
              <Row gutter={1}>
                <Col span={9}>
                  <Form.Item
                    className={styles["form__item"]}
                    name="printer_name"
                    label="Printer Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please type Printer name",
                      },
                      {
                        // async validator(_, value) {
                        //   if (!value || initialData?.group_name === value.toString().trim()) {
                        //     return;
                        //   }
                        //   await checkGroupNameExist(value);
                        // },
                      }
                    ]}
                    hasFeedback
                  >
                    <Input className={styles["form__item-input"]}  />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className={styles["form__item"]}
                    name="site_id"
                    label="Site"
                    rules={[
                      {
                        required: true,
                        message: "Please select site",
                      },
                    ]}

                  >
                    <Select
                      className={styles["form__item-input"]}
                      mode="single"
                      //allowClear
                      // dropdownStyle={{maxHeight: "50px", width: "100%"}}
                      placeholder="Please select"
                      options={siteOptions}
                      optionFilterProp="label"
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      getPopupContainer={trigger => trigger.parentNode}
                    />

                  </Form.Item>
                </Col>
              </Row>

              <Col span={9}>
                <Form.Item
                  className={styles["form__item"]}
                  name="printer_ip"
                  label="Printer IP"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please type Printer ip",
                    },
                    {
                      // async validator(_, value) {
                      //   if (!value || initialData?.group_name === value.toString().trim()) {
                      //     return;
                      //   }
                      //   await checkGroupNameExist(value);
                      // },
                    }
                  ]}
                  hasFeedback
                >
                  <Input className={styles["form__item-input"]}  />
                </Form.Item>
              </Col>


              <Divider className={styles["form-divider"]} />
              <Row>
                <Form.Item>
                  <CustomButton
                    btn_size="medium"
                    type={"submit"}
                    btn_text="Save"
                    htmlType="submit"
                    loading={submitLoading}
                  ></CustomButton>
                  <CustomButton
                    btn_size="medium"
                    type="normal"
                    btn_text="Reset"
                    htmlType="button"
                    onClick={onReset}
                  />
                </Form.Item>
              </Row>
            </Form>
          </DialogCom>
        </div>
      );
  })
