import { ApiTwoTone, CheckCircleTwoTone, CloseCircleTwoTone, CodeTwoTone, DollarTwoTone, ExclamationCircleOutlined, HourglassTwoTone, PauseCircleTwoTone, ProfileTwoTone, ThunderboltTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Modal, Space, Tooltip,Select,Spin,Table, Row, Col } from "antd";
import dateFormat from 'dateformat';
import React, { Fragment, useCallback, useEffect,  useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { CustomTable, CustomButton } from "../../../components";
import { useWritePermissionChecking } from "../../../hook/useWritePermissionChecking";
import { callApi, customMessage, getEncBody } from "../../../utils/common";
import ExportExcel from "../../../utils/exportExcel";
import styles from "./transactionList.module.scss";
import { ICSSPrinterFormInput } from "./ICSSPrinterDetailInput";
import { ICSSPrinterJobDetail } from "./ICSSPrinterJobDetail";
import { useSelector } from "../../../redux/hook";
import { useDispatch } from "react-redux";
import { userSlice } from "../../../redux/user/slice";

const { aesEncryption, generateAESKey } = require("../../../utils/aes");
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});
const axios = require('axios')
var generateSignature = require("../../../utils/generateSignature");
const rsaEncryption = require("../../../utils/rsa");

export const PrinterList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [data, setData] = useState<any[]>([]);
  const newPrinter_childRef = useRef();
  const editPrinter_childRef = useRef();
  const printerjobDetail_childRef = useRef();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [select, setSelect] = useState({
    selectedRowKeys: []
  });
  const { selectedRowKeys } = select;
  const [selectData,setSelectData] = useState([]);
  var [siteOptions,setSiteOptions] = useState([{value: 0,label:"Site" }]);
  const [printerData,setPrinterData] = useState([]);
  const [printerId,setPrinterId] = useState(0);
  const siteid = useSelector((s) => s.user.siteid);
  //var apiHost = "http://localhost:49162";
  const apiconfig = window.apiconfig;
  var apiHost = apiconfig.apihost;
  var rasHost = apiconfig.rasHost;
  var client_sercet = apiconfig.client_sercet
  let columns = [
    {
      title: "Printer ID",
      dataIndex: "printer_id",
      fixed: "left",
      width: 150
    },
    {
      title: "Name",
      dataIndex: "printer_name",
    },
    {
      title: "IP",
      dataIndex: "ip",
    },
    {
      title: "Site Name",
      dataIndex: "location_name",
    },
    {
      title: "Job Count",
      dataIndex: "job_count",
    },
    {
      title: "Status",
      dataIndex: "status",
    }
  ];

  const getPrinters = useCallback(  () =>{
    console.log("site:"+siteid);
    getEncBody({
      "site_id":siteid,
    }).then((body)=>{
      var timestamp = new Date(Date.now()).toISOString();
      var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSQueryPrinterList");

      axios({
        method: 'post',
        url: apiHost+'/ICSSCMSQueryPrinterList',
        data:body,
        httpsAgent: httpsAgent,
        headers:{
            'signature': signature,
            'timestamp': timestamp
        }
      }).then(function (response) {
        console.log(response.data);
        setData(response.data.printers.map((ptr)=>({key:ptr.printer_id,...ptr})))
      }).catch(err=>{
        console.log(err);
        customMessage.error(err.message)
      });
    });

  },[siteid])

  const handleAddPrinter =  async (values) => {
    console.log(`values`, values);
    setSubmitLoading(true);
    var body = await getEncBody({
      locationId : values.site_id,
      ip : values.printer_ip,
      printer_name : values.printer_name
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSAddPrinter");

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSAddPrinter',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      getPrinters();
      //setData(response.data.printers)
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    }).finally(()=>{
      newPrinter_childRef.current.openDialog();
      setSubmitLoading(false);
      customMessage.info("success")
    });
  };

  const handleEditPrinter =  async (values) => {
    console.log(`values`, values);
    console.log("data:",selectData[0]);
    setSubmitLoading(true);
    var body = await getEncBody({
      printerId : selectData[0].printer_id,
      locationId : values.site_id,
      ip : values.printer_ip,
      printer_name:values.printer_name
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSUpdatePrinter");

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSUpdatePrinter',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      getPrinters();
      if(response.data.result.code !== '0'){
        customMessage.info(response.data.result.sys_message)
      }else{
        editPrinter_childRef.current.openDialog();
        customMessage.info("success")
      }
      //setData(response.data.printers)
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
      editPrinter_childRef.current.openDialog();
    }).finally(()=>{
      setSubmitLoading(false);
    });
  };
  const handleRemovePrinter = async (values) => {
    //console.log(`values`, values);
    console.log("data:",selectData[0]);
    setSubmitLoading(true);
    var body = await getEncBody({
        printerId : selectData[0].printer_id,
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSRemovePrinter");

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSRemovePrinter',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      getPrinters();
      customMessage.info("success")
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    }).finally(()=>{
      setSubmitLoading(false);
    });
  };
const refreshJobs = async ()=>{
  var body = await getEncBody({
        printerId : printerId,
  });
  var timestamp = new Date(Date.now()).toISOString();
  var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSQueryPrinterJobStatus");

  axios({
    method: 'post',
    url: apiHost+'/ICSSCMSQueryPrinterJobStatus',
    data:body,
    httpsAgent: httpsAgent,
    headers:{
        'signature': signature,
        'timestamp': timestamp
    }
  }).then(function (response) {
    console.log(response.data);

    setPrinterData(response.data.jobs);
  }).catch(err=>{
    console.log(err);
    customMessage.error(err.message)
  }).finally(()=>{
    setSubmitLoading(false);
  });
}
  useEffect(()=>{
    getEncBody({}).then((body)=>{
      var timestamp = new Date(Date.now()).toISOString();
      var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSQuerySiteList");

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSQuerySiteList',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      var siteopt = [];
      response.data.siteList.forEach(element => {
        siteopt.push({value:element.site_id,label:element.site_name});
      });
      siteopt.push({value:null,label:"All"});
      console.log(siteopt);
      if(siteopt.length > 0)
        setSiteOptions(siteopt);

    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    });
    })

  },[]);

  useEffect(() => {
    getPrinters()
    const timer = setInterval(getPrinters, 10000)
    return () => {
      clearTimeout(timer);
    }
  }, [getPrinters]);

  const openDialog = (type) => {
    let amv: any;
    switch (type) {
       case "edit":
         amv = editPrinter_childRef.current;
         if(selectData.length >0){
            amv.openDialog();
         }
       break;
      case "new":
        amv = newPrinter_childRef.current;
        amv.openDialog();
        break;
      case "remove":
        //amv = removePrinter_childRef.current;
        //if(selectData.length >0){
        //   amv.openDialog();
        //}
        break;
      default:
        break;
    }

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log('selectedRows: ', selectedRows);
      setSelect({
        ...select,
        selectedRowKeys: selectedRowKeys
      });
      setSelectData(selectedRows);
    },
  };
  return (
    <>
    <Row> <Col span={12}>  <div className={styles["btngroup"]}>

        <CustomButton
          onClick={() => openDialog("new")}
          icon="new"
          btn_size={"medium"}
          type={"normal"}
          btn_text={"add"}
        />
        <CustomButton
          onClick={() => openDialog("edit")}
          icon="edit"
          btn_size={"medium"}
          type={"normal"}
          btn_text={"Edit"}
        />
        <CustomButton
          onClick={() => {
            Modal.confirm({
              title: 'Do you want to remove this Printer?',
              icon: <ExclamationCircleOutlined />,
              onOk: () => {
                handleRemovePrinter();
              },
              content: ``
              ,
              okText: 'Yes',
              cancelText: 'No',
            });
          }}
          icon="remove"
          btn_size={"medium"}
          type={"normal"}
          btn_text={"REMOVE"}
        />

      </div></Col> <Col  span={12} > <Row justify="end"><Select

        placeholder="Site"
        className={styles["form__item-input"]}
        onChange={(value)=>{
          console.log(value);
          dispatch(
            userSlice.actions.setSite({
              siteid: value,
            }));
        }}
      >
        { siteOptions.map((val)=>{
          return <Select.Option key={val.value} value={val.value}>{val.label}</Select.Option>;
        })}
      </Select> </Row></Col> </Row>

      <Table
        columns={columns}
        dataSource={data}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              console.log("clicked:"+rowIndex);
              console.log("values:",record);
              setSubmitLoading(true);
              setPrinterId(record.printer_id);

              getEncBody({
                printerId : record.printer_id,
              }).then((body)=>{
                var timestamp = new Date(Date.now()).toISOString();
                var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSQueryPrinterJobStatus");

                axios({
                  method: 'post',
                  url: apiHost+'/ICSSCMSQueryPrinterJobStatus',
                  data:body,
                  httpsAgent: httpsAgent,
                  headers:{
                      'signature': signature,
                      'timestamp': timestamp
                  }
                }).then(function (response) {
                  console.log(response.data);

                  setPrinterData(response.data.jobs);
                  var diag = printerjobDetail_childRef.current;
                  diag.openDialog();
                }).catch(err=>{
                  console.log(err);
                  customMessage.error(err.message)
                }).finally(()=>{
                  setSubmitLoading(false);
                });

              })

            }
          }
        }}
         rowSelection={{
           type: "radio",
           ...rowSelection
         }}

        loading={false}
      ></Table>
      <ICSSPrinterFormInput ref={newPrinter_childRef} initialData={null} title="Add" handleSubmit={handleAddPrinter} submitLoading={submitLoading} />
      <ICSSPrinterFormInput ref={editPrinter_childRef} initialData={selectData} title="Edit" handleSubmit={handleEditPrinter} submitLoading={submitLoading} />
      <ICSSPrinterJobDetail ref={printerjobDetail_childRef} initialData={printerData} title="Print Jobs" handleSubmit={handleEditPrinter} submitLoading={submitLoading} printerId={printerId} handleRefreshJob={refreshJobs} />
    </>
  );
};
