import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MinusCircleOutlined, PlusOutlined  } from '@ant-design/icons';
import moment from 'moment'

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,Table
} from "antd";
import Title from "antd/lib/typography/Title";

import {
  DialogCom,
  CustomButton
} from "../../../components";
import resMessage from "../../../constant/returnMessage";
import { customMessage,getEncBody } from "../../../utils/common";
import styles from "./userGroupForm.module.scss";
import { ICSSTestPrintInput } from "./ICSSTestPrintInput";
import {ICSSCancelJobInput} from "./ICSSCancelJobInput";

const apiconfig = window.apiconfig;
const axios = require('axios')
const { aesEncryption, generateAESKey } = require("../../../utils/aes");
var generateSignature = require("../../../utils/generateSignature");
const rsaEncryption = require("../../../utils/rsa");
var client_sercet = apiconfig.client_sercet;
var rasHost = apiconfig.rasHost;
const https = require('https');
const httpsAgent = new https.Agent({rejectUnauthorized:false});
const title = "Printer Detail";


export const ICSSPrinterJobDetail = forwardRef(({ initialData, title, handleSubmit, submitLoading, printerId,handleRefreshJob }: PropsType, ref) => {
  const [visible, setVisible] = useState(false);
  const [fetching, setFetching] = useState(true);
  const testPrinter_childRef = useRef();
  const cancelJob_childRef = useRef();
  const [select, setSelect] = useState({
    selectedRowKeys: []
  });
  const { selectedRowKeys } = select;
  const [selectData,setSelectData] = useState([]);

  //var apiHost = "http://localhost:49162";
  var apiHost = apiconfig.apihost;
  var timeformat = "DD-M-YYYY HH:mm:ss"
  let columns = [
    {
      title: "Job ID",
      dataIndex: "job_id",
      fixed: "left",
      width: 80,
      sorter: (a, b) =>{
        return a.job_id-b.job_id
      } ,
      sortDirections: ['descend'],
    },
    {
      title: "Tag type",
      dataIndex: "tag_type",
      sorter: (a, b) =>a.tag_type < b.tag_type
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) =>a.status <b.status
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      width: 120,
      sorter: (a, b) =>{
        return moment(a.timestamp,timeformat).diff(moment(b.timestamp,timeformat))
      } ,
      sortDirections: ['descend'],
    },
    {
      title: "EPC",
      dataIndex: "epc",
       render: text => <small>{text}</small>,
       sorter: (a, b) =>a.epc <b.epc
    },
    {
      title: "TID",
      dataIndex: "tid",
      render: text => <small>{text}</small>,
      sorter: (a, b) =>a.tid <b.tid
    },
  ];

  const rowSelection = {
//    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log('selectedRows: ', selectedRows);
       setSelect({
         ...select,
         selectedRowKeys: selectedRowKeys
       });
       setSelectData(selectedRows);
    },
  };
  useImperativeHandle(ref, () => ({
    openDialog() {
      setVisible(!visible);
    },
  }));

  const handleCancelJobTest =  async (values) => {
    console.log(`values`, selectedRowKeys);
    var body = await getEncBody({
        "job_id":selectedRowKeys,
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","ICSSCMSCancelPrintJob");

    axios({
      method: 'post',
      url: apiHost+'/ICSSCMSCancelPrintJob',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    }).finally(()=>{
      cancelJob_childRef.current.openDialog();
      //setVisible(false);
      handleRefreshJob();
    });

  }
  const handlePrintTest =  async (values) => {
    console.log(`values`, values);
    console.log(`initialData`, initialData);
    console.log(`printerId`, printerId);
    var body = await getEncBody({
        "ttis_RefNo":"0",
        "printerId":printerId,
        "tagData":[
          {
              "dsfid":"11011",
              "version":"1",
              "tagType": values.tagType,
              "vehicleClass":values.vehicleClass,
              "vehicleID":"00000011",
              "AccessPW":"00000000",
              "tag_refNo":"123456",
              "IRN":""
          }
        ]
    });
    var timestamp = new Date(Date.now()).toISOString();
    var signature = generateSignature.default(body,timestamp,'CMS', client_sercet, "ICSS","PrintTagCreatePrintJob");
    axios({
      method: 'post',
      url: apiHost+'/PrintTagCreatePrintJob',
      data:body,
      httpsAgent: httpsAgent,
      headers:{
          'signature': signature,
          'timestamp': timestamp
      }
    }).then(function (response) {
      console.log(response.data);
      //setData(response.data.printers)
    }).catch(err=>{
      console.log(err);
      customMessage.error(err.message)
    }).finally(()=>{
        setVisible(false);
    });
  };
  initialData.forEach((item, i) => {
    item.key = item.job_id;
  });
  console.log(initialData);


  const CusTitle = () => {
    return <Title level={4}>{title}</Title>;
  };
      return (
        <div>
          <DialogCom
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            title={<CusTitle />}
          >
          <CustomButton
            onClick={()=>{
              testPrinter_childRef.current.openDialog();
            }}
            icon="new"
            btn_size={"medium"}
            type={"normal"}
            btn_text={"Test Print"}
          />
          <CustomButton
            onClick={()=>{
              if(selectedRowKeys.length >0){
                cancelJob_childRef.current.openDialog();
              }
            }}
            icon="cancel"
            btn_size={"medium"}
            type={"normal"}
            btn_text={"Cancel Job"}
          />
          <Table
            columns={columns}
            dataSource={initialData}
            rowSelection={{
              ...rowSelection
            }}
            loading={false}
          ></Table>
          <ICSSTestPrintInput ref={testPrinter_childRef} initialData={initialData} title="Test Print" handleSubmit={handlePrintTest} submitLoading={submitLoading} />

          <ICSSCancelJobInput ref={cancelJob_childRef} initialData={selectedRowKeys} title="Cancel Print" handleSubmit={handleCancelJobTest} submitLoading={submitLoading} />
          </DialogCom>
        </div>
      );
  })
