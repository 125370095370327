import { DatePicker, Form, Image, Select, Spin } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import icon_arrow from "../../assets/icons/icon_arrow.png";
import icon_calendar from "../../assets/icons/icon_calendar.png";
import { callApi, customMessage } from "../../utils/common";
import { CustomButton } from "../button";
import styles from "./sideFormTxn.module.scss";
const { RangePicker } = DatePicker;

interface SearchDataType {
  siteName: string[];
  parkingLot: string[];
  chargingDateRange: Date[];
}

interface PropsType {
  setSearchPara: Function
  searchPara: SearchDataType
  recordCount: number
}

interface SiteOptionList {
  site_list: SiteOption[];
}

interface SiteOption {
  site_id: string;
  site_name: string;
}

interface ChargerOptionList {
  charger_list: ChargerOption[];
}

interface ChargerOption {
  id: string;
  parkingLot: string;
}


export const SideFormTxn: React.FC<PropsType> = ({ setSearchPara, searchPara, recordCount }) => {
  const [siteOptionList, setSiteOptionList] = useState<SiteOptionList | null>(null);
  const [chargerOptionList, setChargerOptionList] = useState<ChargerOptionList | null>(null);
  const [fetching, setFetching] = useState(true);

  const onFinish = (values: any) => {
    console.log("values:", values);
    setSearchPara(values)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };


  const dateTimeFormat = "YYYY-MM-DD HH:mm"
  const getOptionList = async () => {
    try {
      // const siteOptionResult = await callApi(`/site/v1/site?byUserId=true`, 'get')
      // setSiteOptionList({ site_list: siteOptionResult });
      // const chargerOptionResult = await callApi(`/charger/v1/charger/dropdownlist?sites=${JSON.stringify(siteOptionResult)}`, 'get')
      // setChargerOptionList({ charger_list: chargerOptionResult });

      if (!siteOptionResult || !chargerOptionResult)
        setFetching(true)
    }
    catch (err) {
      customMessage.error(err.message);
    }
  }

  useEffect(() => {
    async function init() {
      await getOptionList();
      setFetching(false)
    }
    init();
  }, []);

  const siteOptions = siteOptionList?.site_list?.map(g => ({ value: g.site_id, label: g.site_name }));
  const chargerOptions = chargerOptionList?.charger_list?.map(g => ({ value: g.parkingLot, label: g.parkingLot }));
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        className={styles["form"]}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={styles["form-headerBox"]}>
          <span className={styles["form-headerBox__text"]}>{recordCount} record(s)</span>
        </div>
        <Form.Item
          label="Charging Date Time"
          name="chargingDateRange"
          className={styles["form__item"]}
          initialValue={[moment(searchPara.chargingDateRange[0]), moment(searchPara.chargingDateRange[1])]}
        >
          <RangePicker
            showTime
            showNow
            allowClear={false}
            format={dateTimeFormat}
            placeholder={['Start Date Time', 'End Date Time']}
            className={styles["form__item-input"]}
            suffixIcon={<Image src={icon_calendar} />}
          />
        </Form.Item>

        <Form.Item label="Site Name" name="siteName" className={styles["form__item"]}>
          <Select
            mode="multiple"
            bordered={false}
            className={styles["form__item-input"]}
            placeholder={"Please select"}
            suffixIcon={<img src={icon_arrow} alt="" />}
            options={siteOptions}
            optionFilterProp="label"
            notFoundContent={fetching ? <Spin size="small" /> : null}
          >
          </Select>
        </Form.Item>

        <Form.Item label="Parking Lot" name="parkingLot" className={styles["form__item"]}>
          <Select
            mode="multiple"
            bordered={false}
            className={styles["form__item-input"]}
            placeholder={"Please select"}
            suffixIcon={<img src={icon_arrow} alt="" />}
            options={chargerOptions}
            optionFilterProp="label"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            allowClear
          >
          </Select>
        </Form.Item>

        <div className={"form_item-submit"}>
          <CustomButton
            type={"normal"}
            htmlType="submit"
            btn_text="SEARCH"
            width="100%"
            btn_size="large"
          />
        </div>
      </Form>
    </>
  );
};
